@import url(../../style/vars.css);

.menu {
  position: fixed;
  left: 0;
  right: 0;
  margin: 4rem auto 0;
  max-height: calc(100vh - 4rem);
  width: 100%;
  max-width: 30rem;
  background-color: var(--var-body-bg);
  padding: 0.5rem;
  border: .1rem solid var(--var-border-color);
  border-radius: 6px;
  box-shadow: 0 0 1rem #aaa;
  z-index: 100;
  position: fixed;
  animation: appear .1s 1 forwards;
  overflow: scroll;
}

.spotlight {
  font-size: 1.4rem;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 0 5px var(--var-border-color);
  border: 1px solid #aaa;
  padding: .5rem;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.links {
  display: block;
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
}

.links li {
  display: block;
}

.links li a {
  display: block;
  padding: 0.5rem;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.links li a:focus,
.links li a:active {
  color: #000;
}
