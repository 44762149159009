@import url(../../style/vars.css);

.container {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
}

.header {
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  padding: 1rem;
  border-bottom: 1px solid var(--var-border-color);
  background: var(--var-body-bg);
  font-size: 1.2rem;
  display: flex;
}

.loginLink {
  text-align: center;
  font-size: 2rem;
  margin: calc(50vh - 1rem);
}

.logo {
  font-weight: 700;
  display: inline-block;
  margin-right: 2rem;
  color: var(--var-body-font-color);
}

.navActive {
	color: #666;
}

.logout {
  color: rgb(187, 2, 2);
}
