:root {
	--var-body-bg: #FAFAFA;
	--var-body-font-color: #444;
	--var-link-color: #0096cc;
  --var-border-color: #ddd;
}

@media (prefers-color-scheme: dark) {
  :root {
    --var-body-bg: #ddd;
		--var-body-font-color: #444;
    --var-border-color: #bbb;
  }
}
