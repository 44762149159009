@import url('./vars.css');

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: var(--var-body-bg);
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	color: var(--var-body-font-color);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}

button {
	cursor: pointer;
}

input[type="text"],
input[type="number"],
input[type="password"],
#app .token-input-container,
textarea {
	appearance: none;
	padding: 0.2rem;
	background-color: #fff;
	border: 1px solid #ccc;
	color: var(--var-body-font-color);
	border-radius: 0;
	box-shadow: none;
	width: 100%;
	transition: all .1s ease-in;
}

input[type="text"]:active,
input[type="number"]:active,
input[type="password"]:active,
textarea:active,
#app .token-input-container.token-input-container--focused,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
textarea:focus {
	outline: none;
	border-color: var(--var-link-color);
	resize: vertical;
}

.rc-md-editor .editor-container .sec-md .input {
	font-size: 1rem !important;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

img {
	max-width: 100%;
}

button {
	appearance: none;
	color: #fff;
	padding: 0.5rem 1rem;
	background-color: var(--var-link-color);
	border: 0;
	transition: all .1s ease-in;
}

button:hover:not(:disabled) {
	box-shadow: 0 0 .2rem #aaa;
}

button:active {
	background-color: #000;
}

button:disabled {
	opacity: .5;
	cursor: default;
}

a {
	color: var(--var-link-color);
	transition: all .1s ease-in;
}

a:hover {
	color: #000;
}

[data-title] {
	position: relative;
}

[data-title]:before {
  display: none;
  position: absolute;
	font-size: .8rem;
  left: 0;
  top: 100%;
  background-color: #000;
  color: #fff;
  content: attr(data-title);
  padding: .4rem;
  border-radius: 4px;
}

[data-title]:hover:before{
  display: block;
}
