.spinner {
  text-align: center;
  padding: 1rem;
}

.spinner span {
  display: inline-block;
  animation: spin 5s linear infinite forwards;
  transform: rotate(20deg);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
