@import url(../../style/vars.css);

.layout {
  display: flex;
  padding: .5rem;
  border-top: 1px solid var(--var-border-color);
}

.mainData {
  flex: 3;
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid var(--var-border-color);
}

.metaData {
  flex: 1;
}

.layout input[type=text],
.layout input[type=number],
.layout input[type=password],
.layout textarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.2rem;
}

.layout textarea {
  min-height: 10rem;
}

.layout label {
  display: block;
  margin: 1rem 0  .5rem ;
}
